import React from "react";
import ReactDOM from "react-dom";
import { withRouter,Redirect  } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";

import AsyncSelect from "react-select/async";

class Test_input extends React.Component {
    constructor(props)
    {
         super(props);
         this.state = {
             
             Numero_de_serie__c: '',
             Emplacement: '',
             product_found_id: '',
             product_found_name: '',
             compte_array: '',
             compte_array1: '',
             Value: '',
             selectedValue: '',
             option: []
    
         }
    }

  componentDidMount()
  {
    /*this.setState({option: [
        { value: "Spring", label: "Spring" },
        { value: "Summer", label: "Summer" },
        { value: "Autumn", label: "Autumn" },
        { value: "Winter", label: "Winter" }
      ]});*/
      this.load_data();
  }
  load_data(){
    const cookies = new Cookies();
    let token =  cookies.get('sf_access_token');
    let url  = "https://dentalsoft--uat.my.salesforce.com/services/data/v52.0/query/?q=SELECT%20Id%2C%20Name%2C%20RecordType.Name%20FROM%20Account%20WHERE%20Name%20LIKE%20%27%25"+this.state.Value+"%25%27%20OR%20Identifiant_du_compte__c%20%3D%20%27"+this.state.Value+"%27&page=1&start=0&limit=25";

    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.Authorization = `Bearer ${token}`;

    axios.get(url)
          .then(function (response) {
            let optionss = response.data.records.map( records => ({ value: records.Id, label: records.Name }));
           
            this.setState({option: optionss});
            this.state.option = optionss;
            console.log(this.state.option);
            this.forceUpdate();
          //  let options = dataArr.map( records => ({ value: records.Id, label: records.Name }));
          
        
       
             
          })
          .catch(function (error) {
            console.log(error);
          });

  }
  loadOptions = inputValue => {
    return new Promise((resolve, reject) => {
      // using setTimeout to emulate a call to server
      setTimeout(() => {
        this.load_data();
        resolve(this.filter(inputValue));
      }, 2000);
    });
  };
  filter = inputValue =>
    this.state.option.filter(option =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );

  render() {
    return (
      <AsyncSelect defaultOptions cacheOptions loadOptions={this.loadOptions} />
    );
  }
}
export default withRouter(Test_input);