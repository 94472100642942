import React from "react";
import { withRouter,Redirect  } from "react-router-dom";
import { Nav,Container,Row,Col,Tab,Table,Card,Button,Navbar,NavDropdown,Form,Stack,ListGroup } from 'react-bootstrap';
import { BiPrinter } from "react-icons/bi"; 
import { GrPowerReset } from "react-icons/gr"; 
import CurrencyInput from 'react-currency-input-field';
import axios from "axios";
import Cookies from 'universal-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { jsPDF } from "jspdf";
import { renderToString } from "react-dom/server";
import qrcode from "qrcode";
import Nav_bar from "./Nav_bar";
import Menu from "./Menu";
import Swal from "sweetalert2";
import AsyncSelect from 'react-select/async';
import CustomScroll from 'react-custom-scroll';


class Entree extends React.Component {
  constructor(props)
{
     super(props);
     this.state = {
         value: '',
         product_name: '',
         product_found: '',
         product_found_id: '',
         etat: '',
         Numero_de_serie__c: '',
         Price: '',
         Product2Id: '',
         Quantity: 1,
         Status: '',
         Value: '',
         selectedValue: '',

     }
}
  componentDidMount()
  {
    const cookies = new Cookies();
    let token =  cookies.get('sf_access_token');
    let nom = cookies.get('nom');
      if(!token)
      { 
        window.location.href = window.location.origin+"/oauth/login.php";
      }
      else
      {
        if(!nom)
        {
          this.get_compte_info();
        }
      
      }
    

      
  }
  get_produit(params)
  {
    console.log(params);
    if(params == "")
    {
      this.setState({product_found: 'Produit inconnu'});
    }
    else
    {
      const cookies = new Cookies();
    let token =  cookies.get('sf_access_token');
    console.log(token); 
    let search = params;
      const baseURL = cookies.get('sf_instance_url')+"/services/data/v52.0/query/?q=SELECT%20Id%2CName%20FROM%20Product2%20WHERE%20ProductCode%20%3D%20%27"+search+"%27&page=1&start=0&limit=25"
    

     const config = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-type': 'application/json'
        },
        data:{}
       }
       
        axios.get(baseURL,  config)
        .then((response) => {
          
        
          
          let array = [];
          let array_msg = [];
            if(response.status == 200)
            {
                if(response.data.totalSize > 0)
                {
                     this.setState({product_found: response.data.records[0].Name,product_found_id: response.data.records[0].Id});
                     console.log(response.data);
                  //  return(response.data.records[0].Name);

                }
                else
                {
                    this.setState({product_found: 'Produit inconnu'});
                    console.log("pas found");
                }
            
                 
               this.forceUpdate();
               
            }
             
          })
          .catch(error => {
             
         });

    }
    



  }
  create_produit()
  {
    console.log(this.state.selectedValue);
    const cookies = new Cookies();
    let token =  cookies.get('sf_access_token');
    const baseURL = cookies.get('sf_instance_url')+"/services/data/v52.0/sobjects/Asset"
    var dte = new Date();
        var code = 'A-' + dte.getTime();

   
    axios.post(baseURL,  {
      AccountId: localStorage.getItem("compte"),
        Etat__c: this.state.etat,
        Name: code,
        Numero_de_serie__c: this.state.Numero_de_serie__c,
        Price: this.state.value,
       Product2Id: this.state.selectedValue.value,
        Quantity: this.state.Quantity,
        Status: this.state.Status,        
     },
     {
       headers: {
        'Authorization': `Bearer ${token}`,
        'Content-type': 'application/json'
       }
     })
        .then((response) => {
          
        
          
          let array = [];
          let array_msg = [];
            if(response.status == 201)
            {
              if(localStorage.getItem('produit'))
              {
               
                
                let array = {code_produit: code,intitulé: this.state.selectedValue.label,etat: this.state.etat};
                var obj = JSON.parse(localStorage.getItem('produit'));
                obj['produit'].push(array);
                localStorage.setItem('produit', JSON.stringify(obj));
              }
              else
              {
                let array = {produit:[{code_produit: code,intitulé: this.state.selectedValue.label,etat: this.state.etat}]};
                localStorage.setItem('produit', JSON.stringify(array));
              }
             
                // 
                toast.success('Le produit a été créé avec succès', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
                 this.forceUpdate();
                
               
            }
             
          })
          .catch(error => {
             
         });
     
  }
  produit_corespondant()
  {
    if(this.state.product_name == "")
    {
      return(<h1>Produit inconnu</h1>)
    }
    else
    {
      return(<h1>{this.get_produit(this.state.product_name)}</h1>);
    }
      
  }
  list_produit()
  {
    if(localStorage.getItem('produit'))
    {
      var obj = JSON.parse(localStorage.getItem('produit'));
      console.log(obj.produit);
      let array = [];
      Object.keys(obj.produit).forEach(key => {
        array.push(<ListGroup.Item key={obj.produit[key].code_produit}>{obj.produit[key].code_produit} / {obj.produit[key].intitulé}</ListGroup.Item>)
      });
      console.log(array);
     // const listItems = obj.produit.map((d) => <ListGroup.Item key={d.name}>{d}</ListGroup.Item>);
      return(<ListGroup>{array}</ListGroup>)

    }
  }
  create_pdf()
  {
    var size = [8, 6];
    var element = document.createElement('div');
    var QRCode = require('qrcode');
   
    if(localStorage.getItem('produit'))
    {
      var doc = new jsPDF("landscape","cm",size);
      var obj = JSON.parse(localStorage.getItem('produit'));
      Object.keys(obj.produit).forEach(key => {
      //  array.push(<ListGroup.Item key={obj.produit[key].code_produit}>{obj.produit[key].code_produit} / {obj.produit[key].intitulé}</ListGroup.Item>)
    
      let img = QRCode.toDataURL(obj.produit[key].code_produit, {type: "png"});
      console.log(img);
     // doc.addImage(img, 'PNG', 0, 0, 5, 5);
QRCode.toDataURL(obj.produit[key].code_produit, {type: "png"})
.then(img => {
  if (key > 0)
  {
    doc.addPage(size, 'l');
  }
console.log(img);
doc.addImage(img, 'PNG', 0, 0, 5, 5);
doc.setFontSize(9);
doc.text('Etat : '+obj.produit[key].etat, 5, 1.5);
var tmp = '';
       
        var textee = obj.produit[key].intitulé;
        var mots = textee.split(' ');  console.log("t");
        console.log(mots);
        var cpt = 1;
        for (var j = 0; j < mots.length; j++) {
            tmp += mots[j] + ' ';
            console.log(tmp); 
            var width = doc.getTextWidth(textee);
            
            if (width > size[0]) {
                doc.text(textee, (size[0] - width) / 2, cpt * 0.25);
                tmp = '';
                cpt++;
            }
            else if (tmp != '')
            doc.text(textee, (size[0] - width) / 2, cpt * 0.5);
            


            var texte = obj.produit[key].code_produit;
            var width = doc.getTextWidth(texte);
            doc.text(texte, 0.5 + ((4 - width) / 2), 5.5);

            console.log(tmp);


        }
 
if(parseInt(key) === parseInt(obj.produit.length-1))
{
  
  doc.output('pdfobjectnewwindow');
}
 


})

     
             

      
     

      });
     
    }
  }
  get_compte_info() 
  {
    const cookies = new Cookies();
    let token =  cookies.get('sf_access_token');
    console.log(token);
   
      const baseURL = window.location.origin+"/oauth/user.php"

     const config = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-type': 'application/json'
        },
        data:{}
       }
       
        axios.get(baseURL,  config)
        .then((response) => {
          
        
          
          let array = [];
          let array_msg = [];
            if(response.status == 200)
            {
             
                cookies.set('image', response.data.photos.picture, { path: '/' });
                cookies.set('nom',response.data.display_name , { path: '/' });
                     this.setState({nom: response.data.display_name,email: response.data.username,photos: response.data.photos.picture});
                 
 

                
            
                 
               this.forceUpdate();
               
            }
             
          })
          .catch(error => {
             
         });
  }
  raz()
  {
    this.setState({product_found: '',product_found_id: '',value: '',etat: '',product_name: '',Numero_de_serie__c: ''});
  }
  verif_compte()
  {
    if(!localStorage.getItem("compte"))
    {
      Swal.fire({
        title: 'Erreur',
        text: "Vous n'avez pas sélectionné de compte de stockage dans les paramètres !",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = window.location.origin+"/compte";
        }
      })
      
    }
    
  }
  disabled_imp()
  {
    if(!localStorage.getItem("produit"))
    {
      return "disabled";
    }
  }
  disable_btn_valider()
  {
    if(this.state.etat === "" || this.state.Status === "" || this.state.Quantity === "" || this.state.value === "" || this.state.Numero_de_serie__c == "")
    {
      return "disabled";
    }
  }
  select_a()
  {
       
  var option = [];
    const getOptions = (input) => {
      console.log(this.state.Value);
        const cookies = new Cookies();
        let token =  cookies.get('sf_access_token');
        const baseURL = cookies.get('sf_instance_url')+"/services/data/v52.0/query/?q=SELECT%20Id%2CName%20FROM%20Product2%20WHERE%20Name%20like%20%27%25"+this.state.Value+"%25%27";
    ///  let url  = "https://dentalsoft--uat.my.salesforce.com/services/data/v52.0/query/?q=SELECT%20Id%2C%20Name%2C%20RecordType.Name%20FROM%20Account%20WHERE%20Name%20LIKE%20%27%25"+this.state.Value+"%25%27%20OR%20Identifiant_du_compte__c%20%3D%20%27"+this.state.Value+"%27&page=1&start=0&limit=25";

        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.Authorization = `Bearer ${token}`;
        
        axios.get(baseURL)
          .then(function (response) {
            option = [];
            Object.keys(response.data.records).forEach(key => {
               // console.log(key, response.data.records[key]);
                option.push({value: response.data.records[key].Id, label: response.data.records[key].Name})
              });
            console.log(response);
            //option = response.data.map( records => ({ value: records.Id, label: records.Name }));
          })
          .catch(function (error) {
            console.log(error);
          });
      } 
       
      const loadOptions = inputValue => {
        return new Promise((resolve, reject) => {
          // using setTimeout to emulate a call to server
          setTimeout(() => {
            getOptions();
           resolve();
          }, 100);
        });
      };
    
      const filter = inputValue =>
        option.filter(option =>
       //   console.log(option.label.toLowerCase().includes(inputValue.toLowerCase()),"<--",option.label.toLowerCase())
        //  option.label.toLowerCase().includes(inputValue.toLowerCase())
        option
        );


       
        const handleInputChange = value => {
          this.setState({Value: value});
        };
     
   
   
  
    
  return (
    <Form.Group className="mb-3" controlId="formBasicPassword">
    <Form.Label>Produit :</Form.Label>
     
      
      <AsyncSelect  defaultOptions cacheOptions  onInputChange={handleInputChange} placeholder={""} noOptionsMessage={() => "Pas de résultat"} onChange={e => this.setState({selectedValue: e})} loadOptions={loadOptions} />
       
     </Form.Group>
  );
  }
  async get_produit_select()
  {
    const cookies = new Cookies();
    let token =  cookies.get('sf_access_token');
    const baseURL = cookies.get('sf_instance_url')+"/services/data/v52.0/query/?q=SELECT%20Id%2CName%20FROM%20Product2%20WHERE%20Name%20like%20%27%25"+this.state.Value+"%25%27";
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.Authorization = `Bearer ${token}`;
    let option = [];
    const data = axios.get(baseURL)
    .then(function (response) {
      option = [];
      Object.keys(response.data.records).forEach(key => {
         // console.log(key, response.data.records[key]);
          option.push({value: response.data.records[key].Id, label: response.data.records[key].Name})
        });
     return option
      //option = response.data.map( records => ({ value: records.Id, label: records.Name }));
    })
    .catch(function (error) {
      console.log(error);
    });
    return data;

    


  }
  test() {
 
   
    // handle input change event
    const handleInputChange = value => {
      this.setState({Value: value});
    };
   
    
   
    const fetchData = () => {
      return  this.get_produit_select();
    }
   
   
  return (
    <Form.Group className="mb-3" controlId="formBasicPassword">
    <Form.Label>Produit :</Form.Label>
          <AsyncSelect
          cacheOptions
          defaultOptions
          value={this.state.selectedValue}
          loadOptions={fetchData}
          onInputChange={handleInputChange}
          onChange={e => this.setState({selectedValue: e})}
        />
     </Form.Group>
   );
  }
  render()
  {

      const option = { locale: 'de-DE',currency: 'EUR', };
      const handleOnValueChange = (valuee) => {
       this.setState({value: valuee});
      };
      const handleOnValueChange1 = (valuee) => {
        this.setState({product_name: valuee.target.value});
        console.log(this.state.product_name);
        this.get_produit(valuee.target.value);
       };

     return(  
      <div class="wrapper">{this.verif_compte()}<Nav_bar/><Menu/>   <div class="content-wrapper">
      <section class="content" style={{padding: "15px"}}> <Card> <Card.Header> <Stack direction="horizontal" gap={3}>
  <div className="">Entrée en stock</div>
  <div className="bg-light border ms-auto"><Button onClick={() => { this.create_pdf() }} disabled={this.disabled_imp
  ()}><BiPrinter/> Imprimer</Button></div>
 
</Stack></Card.Header>
  <Card.Body>
    
   <Form>
   



{this.test()}
    
  <Form.Group className="mb-3">
    <Form.Label size="sm">Etat :</Form.Label>
    <Form.Select size="sm"  onChange={e => this.setState({etat: e.target.value}) }>
      <option></option>
      <option>Neuf</option>
      <option>Occasion</option>
      <option>Vieux matériel</option>
    </Form.Select>
  </Form.Group>



  <Form.Group className="mb-3">
    <Form.Label>Statut :</Form.Label>
    <Form.Select size="sm" onChange={e => this.setState({Status: e.target.value})}>
      <option></option>
      <option>Disponible</option>
      <option>Reservé</option>
      <option>Installé</option>
      <option>Détruit/recyclé</option>
      <option>Installé SAV</option>
      <option>En Réparation</option>
    </Form.Select>
  </Form.Group>


  <Form.Group className="mb-3" controlId="sms1">                   
  <Form.Label>Quantité :</Form.Label>           
  <input   className='form-control w-100 form-control-sm' id="delai_sms1"  value={this.state.Quantity} min={1} onChange={e => this.setState({Quantity: e.target.value})} type="number"></input>
  </Form.Group>



  <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Prix d'achat : </Form.Label>
    <CurrencyInput
                  id="validationCustom04"
                  name="input-1"
                  intlConfig={option}
                  className={`form-control form-control-sm`}
                  onValueChange={handleOnValueChange}
                  decimalsLimit={2}
                  value={this.state.value}
                  step={1}
                />
    
  </Form.Group>



  <Form.Group className="mb-3" controlId="formBasicPassword">
    <Form.Label>N° Série :</Form.Label>
    <Form.Control size="sm" type="text" placeholder="" value={this.state.Numero_de_serie__c} onChange={e => this.setState({Numero_de_serie__c: e.target.value})}/>
  </Form.Group>
 
  <Stack direction="horizontal" gap={3}>
   
  <div className="bg-light border ms-auto">  <Button onClick={() => { this.create_produit() }}   variant="secondary" disabled={this.disable_btn_valider()}>
    Valider
  </Button></div>
   
  <div className="bg-light border">  
  <Button   onClick={() => { this.raz() }} variant="warning" > <GrPowerReset style={{marginTop: "-5px"}}/>
    RAZ
  </Button></div>
</Stack>



</Form>
<ToastContainer />


</Card.Body>

   </Card>
   {this.list_produit()}
  </section></div></div>  
   )
  }
   
   
} 
export default withRouter(Entree);