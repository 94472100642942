import React from "react";
import { withRouter,Redirect  } from "react-router-dom";
import { Nav,Container,Row,Col,Tab,Table,Card,Button,Navbar,NavDropdown,Form,Stack,ListGroup,Image } from 'react-bootstrap';
import { BiPrinter } from "react-icons/bi"; 
import { GrPowerReset } from "react-icons/gr"; 
import CurrencyInput from 'react-currency-input-field';
import axios from "axios";
import Cookies from 'universal-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { jsPDF } from "jspdf";
import { renderToString } from "react-dom/server";
import qrcode from "qrcode";
import Swal from "sweetalert2";
import AsyncSelect from 'react-select/async';
import Menu from "./Menu";
import Nav_bar from "./Nav_bar";



class Affectation extends React.Component {
  constructor(props)
{
     super(props);
     this.state = {
         
         Numero_de_serie__c: '',
         Emplacement: '',
         product_found_id: '',
         product_found_name: '',
         photos: '',
         compte_array: '',
         compte_array1: '',
         Value: '',
         selectedValue: '',
         option: []

     }
}
  componentDidMount()
  {
    const cookies = new Cookies();
    let token =  cookies.get('sf_access_token');
      if(!token)
      {
        window.location.href = window.location.origin+"/oauth/login.php";
   
      }
      else
      {
        this.get_compte_info();
        this.get_compte();
      }
    

      
  }
  get_compte_info() 
  {
    const cookies = new Cookies();
    let token =  cookies.get('sf_access_token');
    console.log(token);
   
      const baseURL = window.location.origin+"/oauth/user.php"

     const config = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-type': 'application/json'
        },
        data:{}
       }
       
        axios.get(baseURL,  config)
        .then((response) => {
          
        
          
          let array = [];
          let array_msg = [];
            if(response.status == 200)
            {
             
                cookies.set(response.data.photos.picture, 'image', { path: '/' });
                cookies.set(response.data.display_name, 'nom', { path: '/' });
                this.setState({nom: response.data.display_name,email: response.data.username,photos: response.data.photos.picture});
                 
 

                
            
                 
               this.forceUpdate();
               
            }
             
          })
          .catch(error => {
             
         });
  }
  get_compte()
  {
    const cookies = new Cookies();
    let token =  cookies.get('sf_access_token');
    console.log(token);
    
      const baseURL = cookies.get('sf_instance_url')+"/services/data/v52.0/query/?_dc=1642496009708&q=SELECT%20Id%2CName%20FROM%20Account%20WHERE%20RecordTypeId%20IN%20(SELECT%20Id%20FROM%20RecordType%20WHERE%20Name%20%3D%20%27Stock%27)&page=1&start=0&limit=25"

     const config = {
        headers: {
            'Authorization': `Bearer ${token}`, 
            'Content-type': 'application/json'
        },
        data:{}
       }
       
        axios.get(baseURL,  config)
        .then((response) => {
          
        
          
          let array = [];
          let array_msg = [];
            if(response.status == 200)
            {
                let array = [];
                console.log(response.data.totalSize);
                if(response.data.totalSize > 0)
                {
                 //    array.push({id: response.data.records[key].Id,name: response.data.records[key].Name})
                    
                     let array = [];
                     if(localStorage.getItem("compte") && localStorage.getItem("compte_nom"))
                     {
                         array.push(<option value={localStorage.getItem("compte")}>{localStorage.getItem("compte_nom")}</option>)
                     }
                     else
                     {
                        array.push(<option value={''}>Choisir un compte</option>)
                     }
                     console.log(response.data.records[0].Id);
                     Object.keys(response.data.records).forEach(key => {
                        if(localStorage.getItem("compte") !== response.data.records[key].Id)
                        {
                            array.push(<option value={response.data.records[key].Id}>{response.data.records[key].Name}</option>)
                        }
                       
                     });
                     console.log(array);  
                     this.setState({compte_array: array});
                    

                }

                
            
                 
               this.forceUpdate();
               
            }
             
          })
          .catch(error => {
             
         });




  }
  
  
  list_compte()
  {
    console.log(this.state.compte_array)
    const set_cookies = (event) => {
        var index = event.nativeEvent.target.selectedIndex;
        localStorage.setItem('compte', event.nativeEvent.target[index].value)
        localStorage.setItem('compte_nom', event.nativeEvent.target[index].text)
        toast.success('Opération réalisée avec succès', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
      };
   
    if(this.state.compte_array.length >0) 
    {
    
     
      return(<Form.Group className="mb-3">
      <Form.Label>Compte de stockage :</Form.Label>  
      <Form.Select onChange={e => set_cookies(e)}>
       {this.state.compte_array}
      </Form.Select>
      <ToastContainer />
    </Form.Group>)

    }
  }
  
  

  render()
  {
     
      const handleOnValueChange1 = (valuee) => {
        this.setState({Emplacement: valuee.target.value});
        this.get_compte(valuee.target.value);
       };

     return(   <div class="wrapper"><Nav_bar/><Menu/>   <div class="content-wrapper">
     <section class="content" style={{padding: "15px"}}>
   <Card> <Card.Header> <Stack direction="horizontal" gap={3}>
  <div className="">Paramètres</div>
 
 
</Stack></Card.Header>
  <Card.Body>
    

    <Image src={this.state.photos}></Image>
   <Form>
   
  
 

  <Form.Group className="mb-3" controlId="formBasicPassword">
    <Form.Label>Nom :</Form.Label>
    <Form.Control type="text" placeholder="" value={this.state.nom}/>
  </Form.Group>

  <Form.Group className="mb-3" controlId="formBasicPassword">
    <Form.Label>Email :</Form.Label>
    <Form.Control type="text" placeholder="" value={this.state.email}/>
  </Form.Group>
  
 

  {this.list_compte()}



</Form>
<ToastContainer />


</Card.Body>

   </Card>
 
   </section></div></div>
  
   )
  }
   
   
}
export default withRouter(Affectation);