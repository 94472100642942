import React from "react";
import { withRouter,Redirect  } from "react-router-dom";
import { Nav,Container,Row,Col,Tab,Table,Card,Button,Navbar,NavDropdown,Form,Stack,ListGroup,Image,InputGroup,Spinner } from 'react-bootstrap';
import { BiPrinter } from "react-icons/bi"; 
import { GrPowerReset } from "react-icons/gr"; 
import CurrencyInput from 'react-currency-input-field';
import axios from "axios";
import Cookies from 'universal-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { jsPDF } from "jspdf";
import { renderToString } from "react-dom/server";
import qrcode from "qrcode";
import Swal from "sweetalert2";
import AsyncSelect from 'react-select/async';
import moment from 'moment';
import Menu from "./Menu";
import Nav_bar from "./Nav_bar";



class Composant extends React.Component {
  constructor(props)
{
     super(props);
     this.state = {
         
         Numero_de_serie__c: '',
         Emplacement: '',
         product_found_id: '',
         product_found_name: '',
         asset_id: '',
         composant_quantity: '',
         Quantity: 1,
         loading: '',
         Value: '',
         selectedValue: '',
         option: [],
         Composant_primaire: ''

     }
}
  componentDidMount()
  {
    const cookies = new Cookies();
    let token =  cookies.get('sf_access_token');
      if(!token)
      {
        window.location.href = window.location.origin+"/oauth/login.php";
      
      }
     
    

      
  }
  get_composant(param) 
  { 
 
    const cookies = new Cookies();
    let token =  cookies.get('sf_access_token');
 
    
    const baseURL = cookies.get('sf_instance_url')+"/services/data/v52.0/query/?q=SELECT%20FIELDS%28ALL%29%20FROM%20Asset%20WHERE%20Name%20%3D%20%27"+this.state.Numero_de_serie__c+"%27%20limit%201"
    

     const config = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-type': 'application/json'
        },
        data:{}
       }
       this.setState({ loading: true }, () => {
        axios.get(baseURL,  config)
        .then((response) => {
          
            this.setState({
                loading: false});
          
         
            if(response.status == 200) 
            {
                if(response.data.totalSize>0)
                {
                    var num = parseInt(response.data.records[0].Quantity) || 0;
                    this.setState({product_found_id: response.data.records[0].Product2Id,composant_quantity: num,asset_id: response.data.records[0].Id},function(){
                        this.get_asset_name();
                    })

                }
                else
                {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erreur',
                        text: "Aucun actif n'a été trouvé avec ces critères."
                         
                      })
                }
            
                 
              
               
            }
             
          })
          .catch(error => {
             
         });
        });



  }
  get_asset_name()
  {
    const cookies = new Cookies();
    let token =  cookies.get('sf_access_token');
 
    
      const baseURL = cookies.get('sf_instance_url')+"/services/data/v52.0/sobjects/Product2/"+this.state.product_found_id;
    

     const config = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-type': 'application/json'
        },
        data:{}
       }
       this.setState({ loading: true }, () => {
        axios.get(baseURL,  config)
        .then((response) => {
          
            this.setState({
                loading: false});
          
         
            if(response.status == 200)
            {
                if(response.data.Name)
                {
                  
                    this.setState({product_found_name: response.data.Name},function(){
                        this.forceUpdate();
                    })

                }
                else
                {
                    // erreur 
                }
            
                 
              
               
            }
             
          })
          .catch(error => {
             
         });
        });
  }
  set_composant()
  {
    const cookies = new Cookies();
    let token =  cookies.get('sf_access_token');
 
    
    const baseURL = cookies.get('sf_instance_url')+"/services/data/v52.0/query/?q=SELECT%20FIELDS%28ALL%29%20FROM%20Asset%20WHERE%20Name%20%3D%20%27"+this.state.Composant_primaire+"%27%20limit%201"
    

     const config = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-type': 'application/json'
        },
        data:{}
       }
       this.setState({ loading: true }, () => {
        axios.get(baseURL,  config)
        .then((response) => {
          
            this.setState({
                loading: false});
          
         
            if(response.status == 200)
            {
                if(response.data.totalSize>0)
                {
                  
                 //   this.setState({product_found_id: response.data.records[0].Id,composant_quantity: parseInt(response.data.records[0].Quantity)},function(){
                        this.set_composant_actifs(response.data.records[0].Id);
                        this.set_quantiter_actifs();
              //      })

                }
                else
                {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erreur',
                        text: "Aucun actif n'a été trouvé avec ces critères."
                         
                      })
                }
            
                 
              
               
            }
             
          })
          .catch(error => {
             
         });
        });
  }
  set_composant_actifs(param)
  {
    const cookies = new Cookies();
    let token =  cookies.get('sf_access_token');
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-type': 'application/json'
        },
        data:{}
       }
    const baseURL = cookies.get('sf_instance_url')+"/services/data/v52.0/sobjects/Asset/"+param;
    axios.get(baseURL,config)
          .then((response) => {
            
          
            
            
              if(response.status == 200)
              {
                
                if(response.data.Observations__c === null)
                {
                    var obs= "";
                }
                else
                {
                    var obs = response.data.Observations__c;
                }
               
                
                axios.patch(baseURL,  {
                    Observations__c: obs+"\n"+moment().format('DD/MM/YYYY') + " - Composant : "+this.state.product_found_name +" - Quantité : " + this.state.Quantity    
                   },
                   { 
                     headers: {
                      'Authorization': `Bearer ${token}`,
                      'Content-type': 'application/json'
                     }
                   })
                      .then((response) => {
                        
                      
                        
                        
                          if(response.status == 204)
                          {
                             
                           
                            
                              toast.success('succès', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                });
                               this.forceUpdate();
                              
                             
                          }
                           
                        })
                        .catch(error => {
                           
                       });
                  
                 
              }
               
            })
            .catch(error => {
               
           });
    
  }
  set_quantiter_actifs()
  {
    const cookies = new Cookies();
    let token =  cookies.get('sf_access_token');
    const baseURL = cookies.get('sf_instance_url')+"/services/data/v52.0/sobjects/Asset/"+this.state.asset_id;
    axios.patch(baseURL,  {
        Quantity: (parseInt(this.state.composant_quantity)-this.state.Quantity)
       },
       {
         headers: {
          'Authorization': `Bearer ${token}`,
          'Content-type': 'application/json'
         }
       })
          .then((response) => {
            
          
            
            
              if(response.status == 204)
              {
                 
               
                this.setState({composant_quantity: (parseInt(this.state.composant_quantity)-this.state.Quantity),Quantity: 1},function(){
                    this.forceUpdate();
                })
                  
                  
                 
              }
               
            })
            .catch(error => {
               
           });
  }
  disabled_valider()
  {
      if(this.state.composant_quantity === 0 || this.state.Composant_primaire === "")
      {
          return "disabled"
      }
  }
  detail_affichage()
  {
      if(this.state.composant_quantity !== "" && this.state.product_found_name !== "")
      {
        return(<>
      
      <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Produit : </Form.Label>
    <Form.Control type="text" placeholder="" value={this.state.product_found_name}  />
    
  </Form.Group>

            <Form.Group className="mb-3" controlId="sms1">                   
            <Form.Label>Quantité disponible : {this.state.composant_quantity} </Form.Label>           
            </Form.Group>
            <Form.Group className="mb-3" controlId="sms1">                   
            <Form.Label>Quantité :</Form.Label>           
            <input className='form-control w-100' id="delai_sms1"  value={this.state.Quantity} min={1} max={this.state.composant_quantity} onChange={e => this.setState({Quantity: e.target.value})} type="number"></input>
            </Form.Group>


            <Form.Group className="mb-3" controlId="sms1">                   
            <Form.Label>Composant Primaire :</Form.Label>           
            <Form.Control type="text" placeholder="" value={this.state.Composant_primaire} onChange={e => this.setState({Composant_primaire: e.target.value})} />
            </Form.Group>

            <Stack direction="horizontal" gap={3}>
            <Button className="ms-auto" onClick={() => { this.set_composant() }}  variant="outline-secondary" id="button-addon2" disabled={this.disabled_valider()}>
      Valider
    </Button>
    </Stack>
            
            </>)
      }
     
  }
  

  

  render()
  {
     
      

     return(  
        <div class="wrapper"><Nav_bar/><Menu/>   <div class="content-wrapper">
        <section class="content" style={{padding: "15px"}}>
     <Card> <Card.Header> <Stack direction="horizontal" gap={3}>
  <div className="">Affectation de Composant</div>
 
 
</Stack></Card.Header>
  <Card.Body>
      

  <Form.Group className="mb-3" controlId="formBasicPassword">
    <Form.Label> N° Actif :</Form.Label>
  <InputGroup className="mb-3">
  <Form.Control type="text" placeholder="" value={this.state.Numero_de_serie__c} onChange={e => this.setState({Numero_de_serie__c: e.target.value})}/>

    <Button onClick={() => { this.get_composant() }}  variant="outline-secondary" id="button-addon2">
      Rechercher
    </Button>
  </InputGroup>
  </Form.Group>

  {this.state.loading ? <Spinner animation="border" /> : this.detail_affichage()}
  
<ToastContainer />


</Card.Body>

   </Card>
 
  </section></div></div>
  
   )
  }
   
   
}
export default withRouter(Composant); 