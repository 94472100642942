import React from 'react';
import styles from '../../styles/css/adminlte.min.css'
import Cookies from 'universal-cookie';
import axios from 'axios';

export default function Menu(props) {
    const cookies = new Cookies();
    let image = cookies.get('image');
    let nom = cookies.get('nom')
    if(nom === "")
    {
        
    let token =  cookies.get('sf_access_token');
    console.log(token);
   
      const baseURL = window.location.origin+"/oauth/user.php"

     const config = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-type': 'application/json'
        },
        data:{}
       }
       
        axios.get(baseURL,  config)
        .then((response) => {
          
        
          
          let array = [];
          let array_msg = [];
            if(response.status == 200)
            {
              image = response.data.photos.picture;
              nom = response.data.display_name;
              this.forceUpdate();
               
            }
             
          })
          .catch(error => {
             
         });
    }

  return(
   
         <aside class="main-sidebar sidebar-dark-primary elevation-4">
         <a   class="brand-link" style={{textDecoration: 'none'}}>
         <img src={require('../../assets/logo.png').default}  class="brand-image img-circle elevation-3" />
         <span class="brand-text font-weight-light">Frodon</span>
         </a>
         <div class="sidebar">
        <div class="user-panel mt-3 pb-3 mb-3 d-flex"> 
        <div class="image">
            <img src={image}  class="brand-image img-circle elevation-3" />
        </div>
        <div class="info">
            <a href="#" class="d-block" style={{textDecoration: 'none'}}>{nom}</a>
        </div>
        </div>
        <nav class="mt-2">
          <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <li class="nav-item">
              <a href="/entree" class="nav-link">
                <i class="nav-icon fas fa-cart-plus"></i>
                <p>
                  Entrée
                </p>
              </a>
            </li>     
            <li class="nav-item">
              <a href="/magazinage" class="nav-link">
                <i class="nav-icon fas fa-exchange-alt"></i>
                <p>
                  Magazinage
                </p>
              </a>
            </li>  
            <li class="nav-item">
              <a href="/affectation" class="nav-link">
                <i class="nav-icon fas fa-tag"></i>
                <p>
                  Affectation
                </p>
              </a>
            </li> 
            <li class="nav-item">
              <a href="/composant" class="nav-link">
              <i class="nav-icon fas fa-microchip"></i>
                <p>
                  Affectation Composant
                </p>
              </a>
            </li>            
            <li class="nav-item">
              <a href="/preparation" class="nav-link">
              <i class="nav-icon fas fa-truck-loading"></i>
                <p>
                  Préparation
                </p>
              </a>
            </li>      
            <li class="nav-item">
              <a href="/opportunite" class="nav-link">
              <i class="nav-icon fas fa-box-open"></i>
                <p>
                Vérification
                </p>
              </a>
            </li>  
            <li class="nav-item">
              <a href="/compte" class="nav-link">
              <i class="nav-icon fas fa-tools"></i>
                <p>
                  Paramètres
                </p>
              </a>
            </li>                          
          </ul>
        </nav>
      </div>
      
         </aside>
       
  
  )
}

 