import React from 'react';
import styles from '../../styles/css/adminlte.min.css';
import Cookies from 'universal-cookie';

 
 

class Deconnexion extends React.Component{
  constructor(props)
  {
    super(props); 
  }
  componentDidMount()
  { 
    const cookies = new Cookies();
    cookies.remove('sf_access_token', { path: '/' });
    cookies.remove('nom', { path: '/' });
    cookies.remove('image', { path: '/' });
    cookies.remove('id_compte', { path: '/' });
    window.location.href = "https://frodon.dev.arakis.fr/oauth/login.php";
  }
 render()
 {
  return("deconnexion");
}
}
export default Deconnexion
 