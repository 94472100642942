import React from "react";
import { withRouter,Redirect  } from "react-router-dom";
import { Nav,Container,Row,Col,Tab,Table,Card,Button,Navbar,NavDropdown,Form,Stack,ListGroup } from 'react-bootstrap';
import { BiPrinter } from "react-icons/bi"; 
import { GrPowerReset } from "react-icons/gr"; 
import CurrencyInput from 'react-currency-input-field';
import axios from "axios";
import Cookies from 'universal-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { jsPDF } from "jspdf";
import { renderToString } from "react-dom/server";
import qrcode from "qrcode";
import Swal from "sweetalert2";
import AsyncSelect from 'react-select/async';
import Nav_bar from "./Nav_bar";
import Menu from "./Menu";



class Affectation extends React.Component {
  constructor(props)
{
     super(props);
     this.state = {
         
         Numero_de_serie__c: '',
         Emplacement: '',
         product_found_id: '',
         product_found_name: '',
         compte_array: '',
         compte_array1: '',
         Value: '',
         selectedValue: '',
         option: []

     }
}
  componentDidMount()
  {
    const cookies = new Cookies();
    let token =  cookies.get('sf_access_token');
      if(!token)
      {
        window.location.href = window.location.origin+"/oauth/login.php";
     
      }
      else
      {
       
      }
    

      
  }
  get_produit(params) 
  { 



    
    const cookies = new Cookies();
    let token =  cookies.get('sf_access_token');
    console.log(token);
    let search = params;
      const baseURL = cookies.get('sf_instance_url')+"/services/data/v52.0/query/?q=SELECT%20Id%2C%20Name%20FROM%20Asset%20WHERE%20Name%20%3D%20%27"+this.state.Numero_de_serie__c+"%27%20OR%20Numero_de_serie__c%20%3D%20%27"+this.state.Numero_de_serie__c+"%27"
    

     const config = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-type': 'application/json'
        },
        data:{}
       }
       
        axios.get(baseURL,  config)
        .then((response) => {
          
        
          
          let array = [];
          let array_msg = [];
            if(response.status == 200)
            {
                if(response.data.totalSize > 0)
                {
                     this.setState({product_found_id: response.data.records[0].Id});
                     this.set_emplacement();

                }
                else
                {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erreur',
                        text: "Aucun actif n'a été trouvé avec ces critères."
                         
                      })
                }
            
                 
               this.forceUpdate();
               
            }
             
          })
          .catch(error => {
             
         });




  }
  get_compte(params)
  {
    const cookies = new Cookies();
    let token =  cookies.get('sf_access_token');
    console.log(token);
    let search = params;
      const baseURL = cookies.get('sf_instance_url')+"/services/data/v52.0/query/?q=SELECT%20Id%2C%20Name%2C%20RecordType.Name%20FROM%20Account%20WHERE%20Name%20LIKE%20%27%25"+search+"%25%27%20OR%20Identifiant_du_compte__c%20%3D%20%27"+search+"%27&page=1&start=0&limit=25"

     const config = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-type': 'application/json'
        },
        data:{}
       }
       
        axios.get(baseURL,  config)
        .then((response) => {
          
        
          
          let array = [];
          let array_msg = [];
            if(response.status == 200)
            {
                let array = [];
                if(response.data.totalSize > 0)
                {
                 //    array.push({id: response.data.records[key].Id,name: response.data.records[key].Name})
                     this.setState({compte_array: response.data.records});
                 

                }

                
            
                 
               this.forceUpdate();
               
            }
             
          })
          .catch(error => {
             
         });




  }
  set_emplacement()
  {
     
    
    const cookies = new Cookies();
    let token =  cookies.get('sf_access_token');
    if(this.state.product_found_id !== "")
    {
        const baseURL = cookies.get('sf_instance_url')+"/services/data/v52.0/sobjects/Asset/"+this.state.product_found_id;
        axios.patch(baseURL,  {
            AccountId: this.state.selectedValue.value,      
           },
           {
             headers: {
              'Authorization': `Bearer ${token}`,
              'Content-type': 'application/json'
             }
           })
              .then((response) => {
                
              
                
                
                  if(response.status == 204)
                  {
                     
                   
                      // 
                      toast.success('Opération réalisée avec succès', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        });
                       this.forceUpdate();
                      
                     
                  }
                   
                })
                .catch(error => {
                   
               });
    }
    else
    {
        Swal.fire({
            icon: 'error',
            title: 'Erreur',
            text: "Aucun actif n'a été trouvé avec ces critères."
             
          })
    }

     
  }
  set_class_btn()
  {
      if(this.state.Numero_de_serie__c === "" || this.state.selectedValue === "")
      {
          return "disabled"
      }
      
  }
  list_compte()
  {
    if(this.state.compte_array.length)
    {
      let array = [];
      Object.keys(this.state.compte_array).forEach(key => {
        array.push(<ListGroup.Item key={this.state.compte_array[key].Id}>{this.state.compte_array[key].Name}</ListGroup.Item>)
      });
      console.log(array);
     // const listItems = obj.produit.map((d) => <ListGroup.Item key={d.name}>{d}</ListGroup.Item>);
      return(<Card style={{ height: "400px",overflowY: "scroll"}}><ListGroup>{array}</ListGroup></Card>)

    }
  }
  
  raz()
  {
    this.setState({Emplacement: '',Numero_de_serie__c: ''});
  }
  select_a()
  {
       
  var option = [];
    const getOptions = (input) => {
        const cookies = new Cookies();
        let token =  cookies.get('sf_access_token');
      let url  = cookies.get('sf_instance_url')+"/services/data/v52.0/query/?q=SELECT%20Id%2C%20Name%2C%20RecordType.Name%20FROM%20Account%20WHERE%20Name%20LIKE%20%27%25"+this.state.Value+"%25%27%20OR%20Identifiant_du_compte__c%20%3D%20%27"+this.state.Value+"%27&page=1&start=0&limit=25";

        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.Authorization = `Bearer ${token}`;
        
        axios.get(url)
          .then(function (response) {
            option = [];
            Object.keys(response.data.records).forEach(key => {
                console.log(key, response.data.records[key]);
                option.push({value: response.data.records[key].Id, label: response.data.records[key].Name})
              });
            console.log(response);
            //option = response.data.map( records => ({ value: records.Id, label: records.Name }));
          })
          .catch(function (error) {
            console.log(error);
          });
      } 
       
      const loadOptions = inputValue => {
        return new Promise((resolve, reject) => {
          // using setTimeout to emulate a call to server
          setTimeout(() => {
            getOptions();
            resolve(filter(inputValue));
          }, 100);
        });
      };
    
      const filter = inputValue =>
        option.filter(option =>
          option.label.toLowerCase().includes(inputValue.toLowerCase())
        );


       
    
     
   
   
  
    
  return (
    <Form.Group className="mb-3" controlId="formBasicPassword">
    <Form.Label>Compte de destination :</Form.Label>
     
      
      <AsyncSelect defaultOptions cacheOptions  placeholder={""} noOptionsMessage={() => "Pas de résultat"} onChange={e => this.setState({selectedValue: e})} loadOptions={loadOptions} />
       
     </Form.Group>
  );
  }
  render()
  {
     
      const handleOnValueChange1 = (valuee) => {
        this.setState({Emplacement: valuee.target.value});
        this.get_compte(valuee.target.value);
       };

     return(  
        <div class="wrapper"><Nav_bar/><Menu/>   <div class="content-wrapper">
     
  

   
        <section class="content" style={{padding: "15px"}}>
         <Card> <Card.Header> <Stack direction="horizontal" gap={3}>
  <div className="">Affectation</div>
 
 
</Stack></Card.Header>
  <Card.Body>
    
   <Form>
   
  
 {this.select_a()}

  <Form.Group className="mb-3" controlId="formBasicPassword">
    <Form.Label>N° Série :</Form.Label>
    <Form.Control type="text" placeholder="" value={this.state.Numero_de_serie__c} onChange={e => this.setState({Numero_de_serie__c: e.target.value})}/>
  </Form.Group>
 
  <Stack direction="horizontal" gap={3}>
   
  <div className="bg-light border ms-auto">  <Button onClick={() => { this.get_produit() }}   variant="primary" disabled={this.set_class_btn()}>
    Valider
  </Button></div>
   
  <div className="bg-light border">  
  <Button   onClick={() => { this.raz() }} variant="warning" > <GrPowerReset style={{marginTop: "-5px"}}/>
    RAZ
  </Button></div>
</Stack>



</Form>
<ToastContainer />


</Card.Body>

   </Card>
 
   </section></div></div>
  
   )
  }
   
   
}
export default withRouter(Affectation); 