import { Navbar } from "react-bootstrap";
import { BrowserRouter as Router, Route, Switch,Redirect,HashRouter } from "react-router-dom";
import Index from "./components/Index";
import Affectation from "./components/views/Affectation";
import Composant from "./components/views/Composant";
import Compte from "./components/views/Compte";
import Deconnexion from "./components/views/Deconnexion";
import Entree from "./components/views/Entree";
import Magazinage from "./components/views/Magazinage";
import Menu from "./components/views/Menu";
import Opportinite from "./components/views/Opportinite";
import Preparation from "./components/views/Preparation";
import Test_input from "./components/views/Test_input";
 
 
 
const App = () => {
 
      
      
  return (
    
    <Router>
      <Switch>
        <Route path='/login' component={Entree} />
        <Route path='/magazinage' component={Magazinage} />
        <Route path='/affectation' component={Affectation} />
        <Route path='/entree' component={Entree} />
        <Route path='/compte' component={Compte} />
        <Route path='/preparation' component={Preparation} />
        <Route path='/opportunite' component={Opportinite} />
        <Route path='/composant' component={Composant} />
        <Route path='/deconnexion' component={Deconnexion} />
        <Route path='/' component={Entree} />
      </Switch>
    </Router> 
    
  );
}

export default App;
