import React from "react";
import { withRouter,Redirect  } from "react-router-dom";
import { Nav,Container,Row,Col,Tab,Table,Card,Button,Navbar,NavDropdown,Form,Stack,ListGroup } from 'react-bootstrap';
import { BiPrinter } from "react-icons/bi"; 
import { GrPowerReset } from "react-icons/gr"; 
import CurrencyInput from 'react-currency-input-field';
import axios from "axios";
import Cookies from 'universal-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { jsPDF } from "jspdf";
import { renderToString } from "react-dom/server";
import qrcode from "qrcode";
import Swal from "sweetalert2";
import Menu from "./Menu";
import Nav_bar from "./Nav_bar";




class Magazinage extends React.Component {
  constructor(props)
{
     super(props);
     this.state = {
         
         Numero_de_serie__c: '',
         Emplacement: '',
         product_found_id: '',
         product_found_name: ''

     }
}
  componentDidMount()
  {
    const cookies = new Cookies();
    let token =  cookies.get('sf_access_token');
      if(!token)
      {
        window.location.href = window.location.origin+"/oauth/login.php";
      }
      else
      {
       
      }
    

      
  }
  async get_produit(params)
  {
    const cookies = new Cookies();
    let token =  cookies.get('sf_access_token');
    console.log(token);
    let search = params;
      const baseURL = cookies.get('sf_instance_url')+"/services/data/v52.0/query/?q=SELECT%20Id%2C%20Name%20FROM%20Asset%20WHERE%20Name%20%3D%20%27"+search+"%27%20OR%20Numero_de_serie__c%20%3D%20%27"+search+"%27"

     const config = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-type': 'application/json'
        },
        data:{}
       }
       
        const data = axios.get(baseURL,  config)
        .then((response) => {
          
        
          
          let array = [];
          let array_msg = [];
            if(response.status == 200)
            {
                if(response.data.totalSize > 0)
                {
                     this.setState({product_found_id: response.data.records[0].Id,product_found_name: response.data.records[0].Name});
                    return true;

                }
                else
                {
                    this.setState({product_found_id: ''});
                    return false;
                    
                }
            
                 
               this.forceUpdate();
               
            }
             
          })
          .catch(error => {
             
         });
         return await data;



  }
  set_emplacement()
  {

     
        try {
            this.get_produit(this.state.Numero_de_serie__c).then((response) => {
                console.log(response);
                const cookies = new Cookies();
                let token =  cookies.get('sf_access_token');
                if(response == true)
                {
                    const baseURL = cookies.get('sf_instance_url')+"/services/data/v52.0/sobjects/Asset/"+this.state.product_found_id;
                    axios.patch(baseURL,  {
                        Magazinage__c: this.state.Emplacement,      
                       },
                       {
                         headers: {
                          'Authorization': `Bearer ${token}`,
                          'Content-type': 'application/json'
                         }
                       })
                          .then((response) => {
                            
                          
                            
                            
                              if(response.status == 204)
                              {
                                if(localStorage.getItem('magazinage'))
                                {
                                 
                                  
                                  let array = {opération: this.state.product_found_name+' ==> '+this.state.Emplacement };
                                  var obj = JSON.parse(localStorage.getItem('magazinage'));
                                  obj['magazinage'].push(array);
                                  localStorage.setItem('magazinage', JSON.stringify(obj));
                                }
                                else
                                {
                                  let array = {magazinage:[{opération: this.state.product_found_name+' ==> '+this.state.Emplacement }]};
                                  localStorage.setItem('magazinage', JSON.stringify(array));
                                }
                               
                                  // 
                                  toast.success('succès', {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    });
                                   this.forceUpdate();
                                  
                                 
                              }
                               
                            })
                            .catch(error => {
                               
                           });
                }
                else
                {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erreur',
                        text: "Aucun actif n'a été trouvé avec ces critères."
                         
                      })
                }
            });
          //  const result = this.get_produit(this.state.Numero_de_serie__c);
        
           
          } catch (error) {
            
          }
        
     
    
    

     
  }
  set_class_valider()
  {
      if(this.state.product_found_id === "")
      {
          return "disabled";
      }
  }
  list_produit()
  {
    if(localStorage.getItem('magazinage'))
    {
      var obj = JSON.parse(localStorage.getItem('magazinage'));
      console.log(obj.magazinage);
      let array = [];
      Object.keys(obj.magazinage).forEach(key => {
        array.push(<ListGroup.Item key={obj.magazinage[key].opération}>{obj.magazinage[key].opération}</ListGroup.Item>)
      });
      console.log(array);
     // const listItems = obj.produit.map((d) => <ListGroup.Item key={d.name}>{d}</ListGroup.Item>);
      return(<ListGroup>{array}</ListGroup>)

    }
  }
  
  raz()
  {
    this.setState({Emplacement: '',Numero_de_serie__c: ''});
  }
  valid_btn()
{
    if(this.state.Emplacement === "" || this.state.Numero_de_serie__c == "")
    {
        return("disabled");
    }
}
  render()
  {
     
      const handleOnValueChange1 = (valuee) => {
        this.setState({Emplacement: valuee.target.value});
        this.get_produit(valuee.target.value);
       };

     return(  
        <div class="wrapper"><Nav_bar/><Menu/>   <div class="content-wrapper">
        <section class="content" style={{padding: "15px"}}>
         <Card> <Card.Header> <Stack direction="horizontal" gap={3}>
  <div className="">Magazinage</div>
 
 
</Stack></Card.Header>
  <Card.Body>
    
   <Form>
  <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Emplacement : </Form.Label>
    <Form.Control type="text" placeholder="" value={this.state.Emplacement} onChange={handleOnValueChange1} />
    
  </Form.Group>


  <Form.Group className="mb-3" controlId="formBasicPassword">
    <Form.Label>N° Série :</Form.Label>
    <Form.Control type="text" placeholder="" value={this.state.Numero_de_serie__c} onChange={e => this.setState({Numero_de_serie__c: e.target.value})}/>
  </Form.Group>
 
  <Stack direction="horizontal" gap={3}>
   
  <div className="bg-light border ms-auto">  <Button onClick={() => { this.set_emplacement() }}   variant="primary" disabled={this.valid_btn()}>
    Valider
  </Button></div>
   
  <div className="bg-light border">  
  <Button   onClick={() => { this.raz() }} variant="warning" > <GrPowerReset style={{marginTop: "-5px"}}/>
    RAZ
  </Button></div>
</Stack>



</Form>
<ToastContainer />


</Card.Body>

   </Card>
   {this.list_produit()}
 </section></div></div>
  
   )
  }
   
   
}
export default withRouter(Magazinage); 