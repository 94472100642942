import React from 'react';
import styles from '../../styles/css/adminlte.min.css'

 
 

class Nav_bar extends React.Component{
  constructor(props)
  {
    super(props); 
  }
  componentDidMount()
  { 
  }
  componentWillReceiveProps()
  {
  }
 render()
 {
   
   
  return(
    <nav class="main-header navbar navbar-expand navbar-light">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
      </li>
    </ul>
   
     
  </nav>
  );
}
}
export default Nav_bar
 