import React from "react";
import { withRouter,Redirect  } from "react-router-dom";
import { Nav,Container,Row,Col,Tab,Table,Card,Button,Navbar,NavDropdown } from 'react-bootstrap';
import { BsCartPlus,BsArrowLeftRight,BsFillTagFill,BsGear } from "react-icons/bs";
import Entree from "./views/Entree";
 



class Index extends React.Component {
  constructor(props)
{
     super(props);
     
}
  componentDidMount()
  {
     
       
         

      
  }
  Redirect_btn()
  {
    this.props.history.push("/entree");
  }
  render()
  {
     return(  <Container><Navbar bg="dark" expand="lg">
     <Container>
       <Navbar.Brand href="#home">Frodon</Navbar.Brand>
       <Navbar.Toggle aria-controls="basic-navbar-nav" />
       <Navbar.Collapse id="basic-navbar-nav">
       </Navbar.Collapse>
     </Container>
   </Navbar>
   <Row>
    <Col>
     
   
  <Button variant="info" href="/entree" className="px-2" style={{fontSize: "2rem",width: "100%",height: "300px", margin: "10px",backgroundColor: "rgba(0,0,0,.03)"}}> <div style={{marginTop: "20%"}}><BsCartPlus/>
     Entrée </div> 
  </Button>
    
 
    
    </Col>
    <Col>
    
    <Button variant="info" href="/magazinage" size="lg" className="px-2" style={{fontSize: "2rem",width: "100%",height: "300px",margin: "10px",backgroundColor: "rgba(0,0,0,.03)"}}> <div style={{marginTop: "20%"}}><BsArrowLeftRight/>
     Magazinage</div>
  </Button>
    
    </Col>
  </Row>
  <Row>
    <Col> 
    <Button variant="info" href="/affectation" size="lg" className="px-2" style={{fontSize: "2rem",width: "100%",height: "300px",margin: "10px",backgroundColor: "rgba(0,0,0,.03)"}}> <div style={{marginTop: "20%"}}><BsFillTagFill/>
     Affectation</div>
  </Button>
    
    </Col>
    <Col>
    
    <Button variant="info" href="/compte" size="lg" className="px-2" style={{fontSize: "2rem",width: "100%",height: "300px",margin: "10px",backgroundColor: "rgba(0,0,0,.03)"}}> <div style={{marginTop: "20%"}}> <BsGear/>
     Paramètres </div>
  </Button>

    </Col>
  </Row>
   
   </Container>);
  }
   
   
}
export default withRouter(Index);